import styled from '@emotion/styled';
import {breakpoints, colors, fonts, fontWeights} from '../../styles/theme';


const TransactionDataItem = styled.span`
    border-radius: 48px;
    border: 0;
    background-color: ${colors.white};
    padding: 5px 15px;
    height: auto;
    width: fit-content;
    box-shadow: -5px 9px 12px 0 rgba(81, 81, 81, 0.17);
    display: block;
    margin: 5px auto;
    font-size: 14px;
    line-height: 18px;
    font-family: ${fonts.sansSerif};
    font-weight: ${fontWeights.semiLight};
    letter-spacing: 0.5px;

    svg {
        margin: 5px 10px 5px 5px;
    }

    @media (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}) {
        font-size: 11px;
        padding: 4px 10px;
    }
`;


export default TransactionDataItem;
